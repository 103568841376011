import * as api from './http'

/* 查询评论回复 */
export function play_comment(params){
    return api.postApi('Course/reply_list', params)
}

/* 修改个人信息(机构) */
export function course_info(params){
    return api.postApi('Home/course_info', params)
}

/* 课程列表—热门课程/为您推荐/热门搜索 */
export function hot_course(params){
    return api.postApi('Home/hot_course', params)
}

/* 添加课程评论 */
export function add_comment(params){
    return api.postApi('My/add_comment', params)
}

/* 生成观看记录 */
export function add_look_log(params){
    return api.postApi('Course/add_look_log', params)
}

/* 学习课程送积分 */
export function study_point(params){
    return api.postApi('Course/study_point', params)
}

/* 评分 */
export function add_course_score(params){
    return api.postApi('My/add_course_score', params)
}

/* 课程加入学习计划 */
export function add_study_plan(params){
    return api.postApi('My/add_study_plan', params)
}

/* 设置计划完成时间 */
export function time_set(params){
    return api.postApi('My/time_set', params)
}

/* 二维码 */
export function course_dealImage(params){
    return api.postApi('Organ/course_dealImage', params)
}

/* 海报图 */
export function scerweima(params){
    return api.postApi('Organ/scerweima', params)
}

/* 查询课程学习人数 */
export function course_people(params){
    return api.postApi('Home/course_people', params)
}

/* 查询课程学习人数 */
export function add_looklog_direct(params){
    return api.postApi('Course/add_looklog_direct', params)
}