<template>
    <!-- 聊天 -->
    <div class="liveChat">
        <!-- 最外边的div -->
        <div>
          <div class="loadNews" v-show="loadMorePro" @click="loadPro">加载更多</div>
            <!-- for 循环的div -->
            <div class="commentFor" v-for="(it, is) in chat" :key="is">
                <!-- 头像div -->
                <div class="commentForLeft">
                    <img :src="it.user.pic" alt="" />
                </div>
                <div class="commentForRig">
                    <p class="commentForRigName">{{ it.user.nick }}</p>
                    <!-- 评论div -->
                    <div class="commentForRigContent">
                        {{ it.content }}
                    </div>
                    <!-- 时间 回复 div -->
                    <div class="commentForRigClick">
                        <p>
                            {{ it.ctime }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "liveChat",
    props: {
        every: {
            type: Array,
            default: [],
        },
        loadMorePro:{
          type:Boolean,
          default:true,
        },
        moreInd:{
          type:Number,
          default:null,
        }
    },
    data() {
        return {
          heigthPro:null,
          more:null,
        };
    },
    created(){
    },
    mounted(){
      this.heigthPro =  document.querySelector('.liveChat').scrollHeight
    },
    computed:{
      chat(){
        console.log('变成什么了',this.heigthPro)
        this.more = this.moreInd
        setTimeout(()=>{
          this.onScroll()
        },50)
        return this.every
      },
    },
    methods: {
      onScroll() { 
        let dom = document.querySelector('.liveChat')
        if(this.more == 1){
          dom.scrollTop  = dom.scrollHeight - this.heigthPro
        }else{
          dom.scrollTop = dom.scrollHeight
        }
      },
      loadPro(){
        this.$emit('live-loadChange', 1)
      }
    },
};
</script>

<style>
.liveChat {
    height: 619px;
    padding: 0 27px;
    text-align: initial;
    overflow-y: auto;
}
/* .commentStyleHeight {
    
} */
/* 加载更多 */
.loadNews{
  text-align: center;
  color: #47d7e3;
}

/* 评论展示 */
.commentFor{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #DDDDDD;
  padding: 30px 0;
}
.commentForLeft{
  flex:1;
}
.commentForLeft img {
    width: 39px;
    height: 39px;
    border-radius: 50px;
    overflow: hidden;
}
.commentForRig{
  flex:14;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.commentForRigName{
  height: 39px;
  display: flex;
  align-items: center;
}
.commentForRigContent {
    font-size: 14px;
    color: #333333;
    margin: 9px 0 18px;
}
.commentForRigClick{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.commentForRigClick p{
  color: #afaeae;
  font-size: 14px;
}
.commentForRigClickPro{
  font-size: 14px;
  color: #545e68;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.commentForRigClickPro>img{
  width: 18px;
  height: 16px;
  margin-right: 8px;
}
.commentForRigMore{
  font-size: 14px;
  color: #333333;
  margin-top: 10px;
}
.sonComment{
  margin-top: 16px;
  border-radius: 10px;
  padding: 10px 36px;
  background: #F8F9FA;
}
.sonCommentFor{
  /* display: flex;
  justify-content: space-between; */
  margin-bottom: 40px;
}
.sonCommentLeft{
  flex:1;
}
.sonCommentLeft img{
  width: 39px;
  height: 39px;
  border-radius: 50px;
  overflow: hidden;
}
.sonCommentRig{
  flex:11;
  display: flex;
  flex-direction:column;
}
.sonCommentRigHead{
  margin-bottom: 8px;
  display: flex;
  justify-content:space-between;
  align-items:center;
}
.sonCommentRigHeadName>p{
  font-size: 16px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 5px;

}
.sonCommentRigHeadName>div{
  font-size: 14px;
  color: #afaeae;
}
.sonCommentRigClick{
   font-size: 14px;
  color: #545e68;
  display: flex;
  align-items: center;

}
.sonCommentRigClick>img{
  width: 18px;
  height: 16px;
  margin-right: 8px;
  margin-top: 3px;
}
.fatherReply{
  display: flex;
  justify-content:center;
  margin: 22px 0 0;
}
.fatherReply>div{
  width: 80%;
  height: 30px;
  border:1px solid #bdb7b7;
}
.fatherReply>div>input{
  width: 97%;
  height: 100%;
  font-size: 14px;
  padding-left: 10px;
}
.fatherReply>p{
  width: 75px;
  height: 32px;
  background: #3ac6cf;
  display: flex;
  justify-content:center;
  align-items:center;
  color:#fff;
}
</style>