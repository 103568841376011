<template>
<!-- 课程详情 讲师简介 -->
    <div class="lecturercontentHtml">
        <div v-html="wordhtml"></div>
    </div>
</template>

<script>
export default {
    name: "lecturercontentHtml",
    props: {
        wordhtml: {
            type: String,
            default: "",
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>

<style>
.lecturercontentHtml {
    widows: 93%;
    /* height: 1381px; */
    padding: 0 30px;
    overflow-y: auto;
    text-align: initial ;
}
/* .lecturercontentHtml::-webkit-scrollbar{
    display: none;
} */
</style>