import md5 from 'js-md5'

export default {
  appid: 'fkqve6u877',
  secret: '2d0e9e0646c54222ab265d4ed9d8899b',
  userId: '16d97dddca',
  url: 'http://api.polyv.net/live/v1/users/',
  get (channel,uid = '',role = '') {
    const time = new Date().getTime()
    // 1.构造参数
    const params = {
      appId: this.appid,
      timestamp: time,
      channelId:channel
    }
    if(role !==''){
      params.role = role
    }
    if(uid !==''){
      params.userId = uid
    }
    // 2.对加密数组进行字典排序
    var paramString = this.sortParams(params);
    var signString = this.secret + paramString + this.secret
    const sign =  md5(signString).toUpperCase();
    return {
      sign: sign,
      time: time,
      url: `${this.url}${this.userId}/channels?appId=${this.appid}&timestamp=${time}&sign=${sign}`
    }
  },
  sortParams(params) {
    var keys = Object.keys(params).sort();
    var paramsString = '';
    for (var i = 0; i < keys.length; i++) {
      paramsString += keys[i] + params[keys[i]];
    }
    return paramsString;
  }
}
