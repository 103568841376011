<template>
    <div>
        <div class="livePlay">
        <div class="videoBox">
            <!-- 课程介绍 因为保利威的pptjs已经注册了，那么页面必须有这个，所以采用隐藏方式 否则会有报错 :style="tabId==3?'display: block;':'display: none;'" -->
            <div class="htmlWordStyle" :style="pptIndex==0?'display: block;':'display: none;'">
                <div id="ppt" style="width:773px;height:611px;"></div>
            </div>
            <div :style="pptIndex==0?'height: 78%;display: flex;align-items: flex-start;':''">
                <div id="player" :class="{playerOne:pptIndex==0,playerTwo:pptIndex==1}"></div>
            </div>
        </div>
            <div class="content">
                <div class="left">
                    <div class="leftHead">
                        <div class="leftHeadLe">    
                            <div class="leftHeadLeTitle">{{detailWhole.title}}</div>
                            <div class="leftHeadLeNum">
                                <span>学习人数：{{renNum}}人</span> 
                                <span style="margin-left: 24px;">{{detailWhole.score}}分</span>
                            </div>
                        </div>
                        <div class="leftHeadRit">
                            <p @click="shareClick"><img src="@/assets/img/play/6076.png" alt=""><span>分享</span></p>
                            <p @click="dialogVisible = true"><img src="@/assets/img/play/607.png" alt=""><span>评价</span></p>
                        </div>
                    </div>
                    <!-- tab 切换 -->
                    <div class="leftLower">
                        <div style="">
                            <div class="leftLowerTab">
                                <div class="tabStyle" :class="{tabStyleBorder:tabIndex==ia}" v-for="(ie,ia) in tabPlay" :key="ia" @click="tabClick(ia,ie.id)">
                                    <div>{{ie.name}}</div>
                                </div>
                            </div>
                            <!-- 讲师介绍 -->
                            <div v-if="tabId==4">
                                <curriculum :wordHtml="content"></curriculum>
                            </div>
                            <!-- 聊天 -->
                            <div v-if="tabId==5">
                                <live-chat :every="pull_pl" :loadMorePro="loadMore" :moreInd="more" @live-loadChange="loadpro"></live-chat>
                            </div>
                            <!-- 提问 -->
                            <div v-if="tabId==6">
                                <live-quiz :alone="pull_pl2"></live-quiz>
                            </div>
                        </div>
                        <!-- 评论input框 -->
                        <div class="commentInp" v-if="tabId==5||tabId==6">
                            <p>发表评论</p>
                            <div class="commentInpPro">
                                <div class="commentInpProBorder">
                                    <img src="@/assets/img/play/editicon.png" alt="">
                                    <input type="text" v-model="commentContent" placeholder="在此写下您的看法">
                                </div>
                                <div class="commentInpProBut" @click="commentClick">发表评论</div>
                            </div>
                        </div>
                        <!-- 广告 -->
                        <div style="height:91px;width:90%;padding:30px 43px;" v-if="imgPro.image != null">
                            <img style="height:100%;width:100%;" :src="imgPro.image">
                        </div>
                    </div>
                </div>
                <!-- 右侧 推荐内容 -->
                <div class="right">
                    <div style="background: #fff;padding: 27px 27px 10px;">
                        <div class="rightHead">
                            <img src="@/assets/img/play/247.png" alt="" />
                            <span>热门课程</span>
                        </div>
                        <!-- 推荐课程 -->
                        <div class="rightDetail" v-for="(it,is) in hotList.res" :key="is" :class="{curr:is%2 == 0}" @click="goother({path:'/play',query:{id:it.id}})">
                            <!-- 有图换这个 -->
                            <img :src="it.face_img" alt="" class="rightDetailimg">
                            <div class="rightDetailTitle">
                                {{it.title}}
                            </div>
                            <div class="rightDetailTime">
                                {{it.duration}}
                            </div>
                            <div style="display: flex;align-items: center;">
                                <div class="rightDetailName" v-for="(ic,iz) in it.lecturer_info" :key="iz">
                                    <img :src="ic.portrait" alt="">
                                    <div>{{ellipsText(ic.real_name)}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="课程评分" :visible.sync="dialogVisible" width="20%">
            <div class="newsConent">
                <div class="newsConentExb"><span>课程先进性</span><el-rate v-model="value1" :disabled="scoreBoolean"></el-rate></div>
                <div class="newsConentExb"><span>课程实用性</span><el-rate v-model="value2" :disabled="scoreBoolean"></el-rate></div>
                <div class="newsConentExb"><span>讲师表现力</span><el-rate v-model="value3" :disabled="scoreBoolean"></el-rate></div>
            </div>               
            <div :class="detailWhole.course_score_info == 0?'newsBut0':'newsBut1'" @click="evaluateClick">{{detailWhole.course_score_info == 0?'评价':'您已提交评价'}}</div>
        </el-dialog>
        <el-dialog :visible.sync="share" width="35%">
            <div style="display: flex;justify-content: center;align-items: center;">
                <div class="shareLeft">
                    <img :src="poster" alt="">
                </div>
                <div class="shareRight">
                    <div class="shareRightWord">
                        <p>请下载邀请卡分享给您的好友</p>
                        <p>每成功邀请1位好友完成手机号注册</p>
                        <p>将获得20积分奖励</p>
                    </div>
                    <div class="shareRightBut" @click="download">
                        下载邀请卡
                    </div>
                </div>
            </div>

        </el-dialog>
    </div>
</template>


<script>
import courseware from '@/components/courseware'
import curriculum from '@/components/curriculum'
import liveChat from '@/components/liveChat'
import liveQuiz from '@/components/liveQuiz'
import { course_info,hot_course,add_course_score,course_dealImage,scerweima,course_people,add_looklog_direct,study_point } from '@/utils/Api/play'
import { PolyvChatRoom } from'@/utils/polyv-chatroom.min.js'
import axios from 'axios'
import qs from 'qs'
import sign from '@/utils/sign'
export default {
    name: "livePlay",
    components: {courseware,curriculum,liveChat,liveQuiz},
    data() {
        return {
            id:null,//课程id
            tabIndex:0,//tab切换下标
            tabContent:[],//tab切换展示
            hotList:[],//热门课程
            // 点播
            tabPlay:[{
                id:4,
                name:'课程介绍',
                page:1
            },{
                id:5,
                name:'聊天',
                page:1
            },{
                id:6,
                name:'提问',
                page:1
            }],
            content:"",//富文本内容
            imgPro:[],//轮播图
            comment:[],//评论
            commentContent:null,//评论内容
            vodPlayerJs: 'https://player.polyv.net/livesdk/polyv-live.min.js',
            vid:'',//视频房间号
            uid:'6a62f1d2b2',//视频id
            live:null,//live直播链接 没有用到
            videoId:null,//视频id
            player:null,//保利威播放器
            detailWhole:{plan_time:{}},//全部订单详情
            commentWhether:'',//评论返回信息
            indexVid:null,//课程详情页点击课程目录返回的vid
            catalogIndex:null,//选重下标
            liveSdk:null,
            userInfo:null,//用户信息
            role: 'slice',//用户级别
            userId:'',//用户唯一id
            userName:'',//用户名
            userPic:'',//用户头像
            pull_pl:[],//聊天信息
            pull_pl2:[],//提问信息
            chatroom:null,//聊天方法
            loadMore:true,//判断有没有 加载更多 按钮
            more:-1,//判断页面 是发表评论还是加载更多
            dialogVisible:false,//评价提示框
            value1:0,//评分1
            value2:0,//评分2
            value3:0,//评分3
            numberValue:null,//评分接口返回
            scoreBoolean:false,//评分的布尔值判断
            share:false,//分享
            poster:null,//海报接口返回内容
            qrCode:null,//二维码
            pptIndex:0,//ppt下标 用来控制显隐
            tabId:4,//tab 切换id
            renNum:null,//课程观看人数
            setIn:null,//查看直播观看人事
            ids:0,//视频id
        }
    },
    // 相当于onload
    created(){
        console.log('上个页面传过来的参数',this.$route)
        console.log('用户信息',this.$store.getters.personal)
        this.vid = this.$route.query.vid
        this.id = this.$route.query.id
        this.ids = this.$route.query.ids
        this.fistPosition()
        this.hotCurriculum()
        this.setIn = setInterval(()=>{
            this.coursePeople()
        },1000)
        if(this.$store.getters.personal.id != undefined) {
            this.scerweimaPro()
        }

    },
    // 页面挂载后执行方法
    mounted(){
       this.loadPlayerScript(this.loadPlayer);
    },
    // 页面写在声明周期 检测退出页面 退出之后 卸载播放视频框 
    destroyed() {
        clearInterval(this.setIn)
        this.setIn = null
        if (this.player) {
            this.player.destroy();
        }
    },
    methods: {
        fistPosition(){
            if(this.$store.getters.personal.id == undefined){
               this.userId = 'tempid' + parseInt(Math.random() * 100)
               this.userName = '游客' + parseInt(Math.random() * 100)
               this.userPic = '//livestatic.videocc.net/assets/wimages/missing_face.png'
               this.details();
               return;
            }
            // console.log('用户信息',this.$store.getters.personal)
            this.userId = this.$store.getters.personal.id
            this.userName = this.$store.getters.personal.real_name
            this.pic = this.$store.getters.personal.icon
            this.role = this.$store.getters.personal.capacity == 1 ? 'student' : this.$store.getters.personal.capacity == 2 ? 'teacher' : 'guest'
            this.studyPoint()
            this.details();
        },
        async details(){
            this.detailWhole = await course_info({course_id:this.id})
            this.content = this.detailWhole.introduce
            this.imgPro = this.detailWhole.advert_info
            this.comment = this.detailWhole.comment
            this.addLooklogDirect(this.detailWhole.catalogue[0].id)
            if(this.detailWhole.course_score_info != 0){
                this.value1 = this.detailWhole.course_score_info.advanced
                this.value2 = this.detailWhole.course_score_info.utility
                this.value3 = this.detailWhole.course_score_info.expressive
                this.detailWhole.course_score_info != 0? this.scoreBoolean = true:scoreBoolean = false
            }
            // console.log('评分',this.value1,this.value2,this.value3)
            this.iss();
        },
        // 赠送积分接口
        async studyPoint(){
            await study_point({course_id:this.id,video_id:this.ids})
            // this.$message.success(`获取积分成功`)
        },
        // 热门课程
        async hotCurriculum(){
            this.hotList = await hot_course({type:1,page:1,limit:2})
        },
        // 课程观看人数
        async coursePeople(){
            const laNum = await course_people({course_id:this.id})
            this.renNum = laNum.study_count
            // console.log('人数',this.renNum)
        },
        async addLooklogDirect(e){
            console.log('接口成功',e)
            await add_looklog_direct({course_id:this.id,video_id:e})
        },
        // 检测有没有 script 标签 引入 保利威 js 链接 没有就创建一个 在body标签下边 
        loadPlayerScript(callback) {
            if (!window.polyvLivePlayer) {
                const myScript = document.createElement('script');
                myScript.setAttribute('src', this.vodPlayerJs);
                myScript.onload = callback;
                document.body.appendChild(myScript);
            } else {
                callback();
            }
        },
        
        // 创建一个 保利威 播放视频框 用户查看 播放
        loadPlayer() {
            const params = sign.get(this.vid)
            // console.log('参数',this.vid,params.sign,params.time,sign.appid,this.userId,this.userName,this.userPic)
            this.liveSdk = new PolyvLiveSdk({
                channelId: this.vid,
                sign: params.sign, // 频道验证签名
                timestamp: params.time, // 毫秒级时间戳
                appId: sign.appid, // polyv 后台的appId
                user: {
                    userId: this.userId,
                    userName: this.userName,
                    pic: this.userPic
                },
                chat: true,
            }); 
            // this.liveSdk.player.on('currentTime', (main) => {
            //         console.log('播放进度', main); // 'player'|'ppt'
            //     });
            this.liveSdk.on(PolyvLiveSdk.EVENTS.CHANNEL_DATA_INIT, (event, data) => {
                console.log('播放类型',data)
                if(data.scene == 'alone'){
                    this.pptIndex = 1
                }
                if(data.watchStatus == "playback"){
                    setTimeout(()=>{
                        this.strip();
                        this.stripPro();
                    },400)
                }
                this.liveSdk.setupPlayer({
                    pptEl: '#ppt',
                    el: '#player',
                    width: '100%',
                    height: '100%',
                    type: 'auto',
                    autoplay:true,
                    // switchPlayer:true,
                    controllerPosition:'player',
                    // pptPlaceholder:true
                });
                
            });
            this.listenerMsg(); // 获取历史消息记录
            this.getHistory(); //监听消息
            
        },
        // 回放进度条
        strip(){
            var lsjs = document.getElementsByClassName('plv-live-player-bar-progress-play')[0]
            var tag=document.createElement("p");
            tag.setAttribute('class','pStyle')
            lsjs.appendChild(tag)
            // console.log('9878',lsjs)
        },
        stripPro(){
            var strip = document.getElementsByClassName('pv-ppt-progress-current-bg')[0]
            var tagPro=document.createElement("p");
            tagPro.setAttribute('class','pStyle')
            strip.appendChild(tagPro)
            // console.log('9878',strip)
        },
        /* 监听消息 */
        listenerMsg(){
            console.log('历史记录')
            this.liveSdk.on(PolyvLiveSdk.EVENTS.SEND_MESSAGE, (event, data) => {
                console.log('我发送消息',data)
            });
            this.liveSdk.on(PolyvLiveSdk.EVENTS.S_QUESTION, (event, data) => {
                console.log('私聊',data)
                this.pull_pl2.push(data)
            });
            this.liveSdk.on(PolyvLiveSdk.EVENTS.HISTORY_MESSAGE, (event, data, begin, end) => {
                console.log('聊天室',event, data, begin, end)
                this.pull_pl.unshift(...data)
                if( data.length < 5){
                    this.loadMore = false
                }
                // console.log('信息信息信息',this.pull_pl)
            })
        },
        // 获取历史消息记录
        getHistory(){
            this.liveSdk.getHistoryMessage()
        },
        // 组件点击加载更多
        loadpro(e){
          this.more = 1
          this.getHistory() 
        },
        // tab切换
        tabClick(e,i){
            this.tabIndex = e
            this.tabId = i
        },
        // 输入框点击事件
        commentClick(){
            this.more = -1
            if(this.tabIndex == 3){
                this.send_sl();
                return;
            }
            this.send_gl();
        },
        // 聊天室
        send_gl(){
            this.liveSdk.send(this.commentContent)
            this.commentContent = null;
        },
        // 私聊
        send_sl(){
            this.liveSdk.sendQuestion(this.commentContent)
            this.commentContent = ''
        },
        // 聊天方法
        iss(){
            const params = sign.get(this.vid,this.userId,this.role)
            axios.post('http://api.polyv.net/live/v3/channel/common/get-chat-token',qs.stringify({
                appId: sign.appid,  
                timestamp: params.time,
                sign: params.sign,
                channelId: this.vid,
                userId: this.userId,
                role: this.role
            })).then(res=>{
                // console.log('聊天成功',res)
                this.chatroom = new PolyvChatRoom({
                    roomId: this.vid,
                    userId: this.userId,
                    token: res.data.token,
                    mediaChannelKey: res.data.mediaChannelKey,
                    nick: this.userName,
                    pic: this.userPic,
                    userType: this.role,
                    roomMessage: (data) => {
                        if(data.EVENT == 'T_ANSWER'){
                            // self.scrtop2 += 80
                            this.pull_pl2.push(data)
                            console.log('私聊聊天室',data)
                        }
                        if(data.EVENT == 'SPEAK'){
                            // self.scrtop += 80
                            this.pull_pl.push(data)
                            console.log('别的用户',data)
                        }
                        if(data.EVENT == 'SELF_SPEAK'){
                            // self.scrtop += 80
                            if(this.tabId == 5){
                                this.pull_pl.push({
                                    EVENT: data.EVENT,
                                    content: data.values[0],
                                    id: data.id,
                                    time: data.time,
                                    user: data.user
                                })
                            }else{
                                this.pull_pl2.push({
                                    EVENT: data.EVENT,
                                    content: data.values[0],
                                    id: data.id,
                                    time: data.time,
                                    user: data.user
                                })
                            }
                            
                            console.log('我发送的',data)
                        }
                        console.log('聊天消息',data)
                    }
                })
                
            }).catch(err=>{
                console.log('失败',err)
            })
        },
        // 评分
        evaluateClick(){
            if(this.detailWhole.course_score_info == 0){
                this.addCourseScore();
            }
        },
        async addCourseScore(){
            this.numberValue = await add_course_score({course_id:this.id,advanced:this.value1,utility:this.value2,expressive:this.value3})
            if(this.numberValue.length == 0){
                this.$message.success(`评分成功`)
                setTimeout(()=>{
                    this.dialogVisible = true
                },2000)
            }

        },
        // 截取文字 点点点
        ellipsText(text, num = 4) {
			if (text) {
				if (text.length > num) {
					return text.substring(0, num) + '...';
				} else {
					return text;
				}
			} else {
				return text
			}
		},
          async scerweimaPro(){
             let url = `/pages/players/video_brief?course_id=${this.id}`
             let b = {
                url:url,
                thoken:this.$store.getters.token,
                invite_code:this.$store.getters.personal.code,
                organ_id:this.$store.getters.personal.id,
            }
            console.log('ssj',b)
            this.qrCode = await scerweima(b)
            this.courseDealImage()
        },
        async courseDealImage(){
            let a = {
                course_id:this.id,//	是	string	课程id
                code_url:this.qrCode,//	是	string	二维码地址
                code_x:210,//	是	string	二维码x坐标 大概 800
                code_y:395,//	是	string	二维码y坐标 大概1600
                code_w:80,//	是	string	二维码宽 大概100
                code_h:80,//	是	string	二维码高 大概100
                bill_y:120,//	是	string	海报y坐标 大概30
                bill_w:300,//	是	string	海报宽度 大概1200
                bill_h:10,//	是	string	海报高度 大概300
                head_x:18,//	是	string	头像x坐标 大概200
                head_y:305,//	是	string	头像y坐标 大概1400
                head_w:80,//	是	string	头像宽 大概300
                head_h:80,//	是	string	头像高 大概300
                name_x:85,//	是	string	姓名x坐标 大概200
                name_y:330,//	是	string	姓名y坐标 大概1400
                name_size:12,//	是	string	姓名文字大小 大概40
                title_x_diff:-25,//	是	string	课程名偏移量 大概150
                title_y:70,//	是	string	课程名y坐标 大概1000
                title_size:16,//	是	string	课程名像素 大概40
                title_w:200,//	是	string	课程名宽度 大概700
            }
            this.poster = await course_dealImage(a)
            this.la = this.poster.split('qrcode/')[1]
            console.log('海报图',this.poster)
        },
        // 下载
        download(){
            var image = new Image()
            image.setAttribute('crossOrigin', 'anonymous')
            image.onload = function() {
                var canvas = document.createElement('canvas')
                canvas.width = image.width
                canvas.height = image.height
                var context = canvas.getContext('2d')
                context.drawImage(image, 0, 0, image.width, image.height)
                var url = canvas.toDataURL(this.poster)
                var a = document.createElement('a')
                var event = new MouseEvent('click')
                a.download = 'photo'
                a.href = url
                a.dispatchEvent(event)
            }
            image.src = this.poster
            console.log('图片',image)
        },
        //分享按钮 
        shareClick(){
            if(this.$store.getters.personal.id == undefined){
                this.$message.error(`请先登录账号`);
                return;
            }
            this.share = true
        }
    },
   
};
</script>

<style scoped>
.videoBox{
  width: 100%;
  height: 783px;
  background: #131314;
  display: flex;
  justify-content: center;
  align-items: center;
}
.play {
    width: 100%;
    background: #fafafa;
}
.playerOne{
    width:400px;
    height:225px;
}
.playerTwo{
    width:1200px;
    height:675px;
}

.content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
.left {
    width: 953px;
    margin-right: 19px;
}
.leftHead{
    width: 100%;
    height: 70px;
    text-align:  initial;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.leftHeadLe{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.leftHeadLeTitle{
    font-size: 17px;
    color:#333333;
}
.leftHeadLeNum{
    display: flex;
    flex-direction: row;
    font-size: 14px;
    color: #999999;
}
.leftHeadRit{
    display: flex;
}
.leftHeadRit img{
    width: 19px;
    height: 19px;
}
.leftHeadRit p{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.leftHeadRit p:nth-child(1){
    margin-right: 37px;
}
.leftHeadRit span{
    font-size: 14px;
    color:#999999;
}
.leftUpper {
    /* width: 100%; */
    /* height: 279px; */
    background: #fff;
    padding: 35px 31px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
}
.leftUpperImg {
    /* flex: 1; */
    width: 388px;
    height: 218px;
    background: blue;
    margin-right: 25px;
}
.leftUpperImg img {
    width: 388px;
    height: 218px;
}
.leftUpperWord {
    width: 58%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.leftUpperWordTit {
    display: flex;
    width: 100%;
    height: 49px;
    font-size: 20px;
    font-weight: bold;
}
.leftUpperWordLabel {
    height: 68%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.leftUpperWordLabelNumOne {
    display: flex;
    font-size: 14px;
    color: #9f9f9f;
    margin-bottom: 11px;
}
.leftUpperWordLabelNumTwo {
    display: flex;
    font-size: 14px;
    color: #9f9f9f;
}
.leftUpperWordLabelClick {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.leftUpperWordLabelClickBut {
    display: flex;
}
.leftUpperWordLabelClickButOne {
    width: 131px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #47d7e3;
    border-radius: 4px;
    color: #fff;
    margin-right: 8px;
    cursor: pointer;

}
.leftUpperWordLabelClickButTwo {
    width: 99px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ff7c2b;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}
.leftUpperWordLabelClickOperation {
    display: flex;
}
.leftUpperWordLabelClickOperationImg {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}
.leftUpperWordLabelClickOperation img {
    width: 24px;
    height: 22px;
}
.leftUpperWordLabelClickOperationImg {
    color: #777777;
    font-size: 12px;
}
.leftUpperWordLabelClickOperationImg span {
    margin-top: 4px;
}

/* 课程介绍 */
.leftLower{
    /* height: 1640px; */
    margin: 16px 0 30px;
    background: #fff;
}
.leftLowerTab{
    display: flex;
    text-align: initial;
    padding: 19px 31px;
}

.tabStyle{
    padding: 9px 0;
    font-size: 16px;
    color:#333;
    margin-right: 39px;
    cursor: pointer;
}

.tabStyleBorder{
    font-size: 16px;
    color:#333;
    font-weight: bold;
    border-bottom: 4px solid #51D5DD;
    margin-right: 39px;
    cursor: pointer;
}
/* 富文本样式 */
/* .htmlWordStyle{
    widows: 706px;
    height: 1381px;
    margin: 0 auto;
    text-align: initial ;
    overflow-y: auto;
} */
/* 右边 */
/* 右边 */
.right{
    width: 336px;
    /* background: #fff; */
    /* padding: 0 27px; */
}
.rightHead {
    color: #333333;
    font-size: 24px;
    display: flex;
}
.rightHead img {
    width: 23px;
    height: 31px;
    margin-right: 17px;
}
.rightDetail{
    padding-bottom: 20px;
    cursor: pointer;
}
.rightDetailimg{
    width: 100%;
    height: 140px;
    margin: 27px 0 20px;
    background: #47d7e3;
}
.rightDetailTitle{
    text-align: initial;
}
.rightDetailTime{
    text-align: initial;
    margin: 13px 0 ;
}
.rightDetailName{
    text-align: initial;
    display: flex;
    margin-right: 5px;
}
.rightDetailName img{
    width: 26px;
    height: 26px;
    /* background: #47d7e3; */
    border-radius: 100px;
    overflow: hidden;
    margin-right: 9px;
}
.curr{
    border-bottom: 1px solid #DDDDDD;
}
/* 发表评论 */
.commentInp {
    margin-top: 19px;
    padding: 17px 31px;
}
.commentInp p {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    text-align: initial;
    margin-bottom: 5px;
}
.commentInpPro{
    display: flex;
    height: 52px;
    padding: 0 62px;
}
.commentInpProBorder{
    display: flex;
    align-items: center;
    border: 1px solid #bdb7b7;
    width: 83%;
    padding: 0 13px;
}
.commentInpProBorder img{
    width: 18px;
    height: 20px;
}
.commentInpProBorder input{
    width: 100%;
    height: 100%;
    font-size: 14px;
    margin-left: 18px;
    color: #909090;
}
.commentInpProBut{
    width: 86px;
    height: 100%;
    background: #47d7e3;
    font-size: 16px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
/* 评价 */
.newsConent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.newsConentExb{
    display: flex;
    font-size: 15px;
    color: #666666;
    margin-bottom: 10px;
}
.newsConentExb span{
    margin-right: 37px;
}
.newsBut0{
    width: 192px;
    height: 32px;
    margin: 0 auto;
    background: #47d7e3;
    border-radius: 3px;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
.newsBut1{
    width: 192px;
    height: 32px;
    margin: 0 auto;
    background: #dedede;
    border-radius: 3px;
    font-size: 14px;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}
</style>